.addressForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 0 auto;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.select {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button {
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.button:hover {
  background-color: #0056b3;
}

.responseContainer {
  text-align: left; /* Align text to the left */
  margin-top: 1rem; /* Add space above the response */
  padding: 1rem; /* Add padding for better readability */
  background-color: #f9f9f9; /* Optional: Light background for visibility */
  border: 1px solid #ccc; /* Optional: Border for structure */
  border-radius: 5px; /* Optional: Rounded corners */
  font-family: Arial, sans-serif; /* Ensure a readable font */
  line-height: 1.5; /* Improve readability */
  max-width: 600px; /* Keep the response container consistent with the form */
}
