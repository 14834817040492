.bottomBar {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
  background: white;

  button {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: 1px rgba(0, 0, 0, 0.15) solid;
    background: #2e7d32;
    color: white;
    font-weight: bolder;
    font-size: 1.2rem;
    cursor: pointer;
  }
}
