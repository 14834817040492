@import 'globalVariables';

.sideBar {
  min-width: 250px;
  width: auto;
  display: flex;
  flex-direction: column;
  z-index: 5;
  text-align: left;
  padding: 1rem;
  background: white;
  border-radius: 5px;

  .top {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .bottom {
    display: flex;
    flex-direction: column;

    .link {
      background: #F8F9FC;
      padding: 8px 16px;
      color: #788191;
      border-radius: 5px;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        background: #e3e4e8;
      }

      &.active {
        color: #F8F9FC;
        background: #788191;
      }
    }
  }

  .companyName {
    color: $primary-color;
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;
    align-items: center;

    span {
      padding-left: 0.5rem;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .linkIcon {
    padding-right: 0.5rem;
    font-size: 1.5rem;
  }
}
