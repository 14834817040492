@import 'globalVariables';

.link {
  text-decoration: none;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: white;
  background: $primary-color;
  font-weight: bold;
  margin-bottom: 1rem;
  border-radius: 5px;

  &:hover {
    background: dodgerblue;
  }
}
