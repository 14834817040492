@import 'globalVariables';

.layout {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: $neutral-light-color;
  flex-grow: 1;
  overflow: hidden;

  .header {
    width: 100%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
    padding: 1rem 0 1rem 0;
    font-weight: bolder;
    font-size: x-large;
    z-index: 10;
  }

  .contentLayout {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;

    .content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      margin-left: 2rem;
      border-radius: 5px;
    }
  }
}
