.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5rem;
  text-align: left;
}

.title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.responseContainer {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.responseTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.responseContent {
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
}
